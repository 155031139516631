var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"how-help",attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"how-help__wr -g-d-f -g-ai-c"},[_c('div',{staticClass:"how-help__content"},[(_vm.blok.title)?_c(_vm.heading,{tag:"component",staticClass:"how-help__title -h2 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),(_vm.blok.list && _vm.blok.list.length)?_c('div',{staticClass:"how-help__list -g-d-f -g-fw-w"},_vm._l((_vm.blok.list),function(item,idx){return _c('div',{directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],key:item._uid,staticClass:"how-help__list-item",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":(150 * idx) + 150}},[_c('div',{staticClass:"how-help__list-item-check"},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":require("assets/img/icons/check.svg"),"alt":"&check;","width":"16","height":"16","data-not-lazy":""}})]),_vm._v(" "),_c('p',{staticClass:"-t2 -ws-pl",domProps:{"innerHTML":_vm._s(item.description)}})])}),0):_vm._e()],1),_vm._v(" "),(_vm.blok.image && _vm.blok.image.filename)?_c('div',{staticClass:"how-help__img -g-d-f -g-ai-c",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"}},[_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm.blok.image.filename,"alt":_vm.blok.image.alt,"width":_vm._f("sbImageSize")(_vm.blok.image.filename,'width', 360),"height":_vm._f("sbImageSize")(_vm.blok.image.filename,'height', 505)}})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }